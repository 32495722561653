import React from 'react'

export default function Hero() {
    return (
        <aside>
            <section>
                <h1>Hibiki Factory</h1>
                <div>響製作所</div>
            </section>
        </aside>
    )
}
